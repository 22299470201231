export default {
  alreadySentTitleSsn: {
    id: 'buyback_my_sales_bank_details_modal_ssn_view_preview_description',
    defaultMessage: 'You have already entered the following SSN last 4 digits.',
  },
  alreadySentTitleDrivingLicense: {
    id: 'buyback_my_sales_bank_details_modal_driving_license_view_preview_description',
    defaultMessage:
      'You have already entered the following driver’s license number.',
  },
  alreadySentDescriptionSsn: {
    id: 'buyback_my_sales_bank_details_modal_ssn_view_preview_value',
    defaultMessage: 'Last 4 digits of your SSN',
  },
  alreadySentDescriptionDrivingLicense: {
    id: 'buyback_my_sales_bank_details_modal_driving_license_view_preview_value',
    defaultMessage: 'Driver’s license number',
  },
  ssn: {
    id: 'buyback_my_sales_bank_details_identity_verification_choice_ssn_number',
    defaultMessage: 'Last 4 digits of SSN',
  },
  drivingLicense: {
    id: 'buyback_my_sales_bank_details_identity_verification_choice_driving_license_number',
    defaultMessage: 'Driver’s license number',
  },
  identityInformationsChoiceText: {
    id: 'buyback_my_sales_bank_details_identity_verification_description',
    defaultMessage:
      'We need to confirm your identity to process your payment. You have 2 options: you can provide either your driver’s license number OR the last 4 digits of your Social Security Number (SSN).',
  },
  descriptionSsn: {
    id: 'buyback_my_sales_bank_details_modal_ssn_view_description',
    defaultMessage:
      'Please enter the last 4 digits of your SSN so we can securely confirm your identity.',
  },
  descriptionDrivingLicense: {
    id: 'buyback_my_sales_bank_details_driving_license_description',
    defaultMessage:
      'Please enter your driver’s license number so we can securely confirm your identity.',
  },
  ssnPreviewLabel: {
    id: 'buyback_my_sales_bank_details_modal_ssn_view_input',
    defaultMessage: 'Last 4 digits of your SSN',
  },
  drivingLicensePreviewLabel: {
    id: 'buyback_my_sales_bank_details_driving_license_label',
    defaultMessage: "Driver's license",
  },
  infoBlockTitleSsn: {
    id: 'buyback_my_sales_bank_details_modal_ssn_view_info_title',
    defaultMessage: 'Why we need your SSN',
  },
  infoBlockTitleDrivingLicense: {
    id: 'buyback_my_sales_bank_details_driving_license_info_title',
    defaultMessage: 'Why we need your driver’s license',
  },
  infoBlockDescriptionSsn: {
    id: 'buyback_my_sales_bank_details_modal_ssn_view_info_description',
    defaultMessage:
      'Our payment provider, Adyen requires us to confirm your identity. Otherwise, we can’t process your payment.\nYour SSN will be automatically deleted once your identity is confirmed and you have received your payment.\nYou can find your SSN on your Social Security card, tax returns, or bank statements. ',
  },
  infoBlockDescriptionDrivingLicense: {
    id: 'buyback_my_sales_bank_details_driving_license_info_description',
    defaultMessage:
      'Our payment provider, Adyen requires us to confirm your identity. Otherwise, we can’t process your payment.\nYour driver’s license number will be automatically deleted once your identity is confirmed and you have received your payment.',
  },
  choiceInfoBlockContent: {
    id: 'buyback_my_sales_bank_details_identity_verification_info_description',
    defaultMessage:
      'We work closely with our payment partner, Adyen to confirm your identity, transfer money to your bank account, and prevent fraud.',
  },
  choiceInfoBlockTitle: {
    id: 'buyback_my_sales_bank_details_identity_verification_info_title',
    defaultMessage: 'How we protect your data',
  },
  required: {
    id: 'buyback_shared_required_field',
    defaultMessage: 'This field is required',
  },
  lengthError: {
    id: 'buyback_my_sales_bank_details_modal_ssn_view_error_length',
    defaultMessage:
      'You must provide the last 4 digits of your social security number',
  },
  formatError: {
    id: 'buyback_my_sales_bank_details_modal_driving_license_format_error',
    defaultMessage:
      "Driver's licence should only accept alphanumeric characters and dash",
  },
}
