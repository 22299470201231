<template>
  <div class="mt-7 pt-56 first:pt-24">
    <h3 class="heading-3 pb-8">{{ section.title }}</h3>

    <!-- Mobile -->
    <div v-if="isMobile" class="grid grid-cols-1">
      <RevList>
        <SaleDetailsSectionItem
          v-for="item in section.items"
          :key="item.id"
          :item="item"
        />
      </RevList>
    </div>
    <!-- Desktop -->
    <div v-else class="grid grid-cols-2 gap-x-56">
      <RevList>
        <SaleDetailsSectionItem
          v-for="item in evenSection"
          :key="item.id"
          :item="item"
        />
      </RevList>
      <RevList>
        <SaleDetailsSectionItem
          v-for="item in oddsSection"
          :key="item.id"
          :item="item"
        />
      </RevList>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { RevList } from '@ds/components/List'

import type { SaleDetailsSection } from '~/scopes/buyback/api/getCustomerSaleDetails/getCustomerSaleDetails.adapter'

import SaleDetailsSectionItem from './SaleDetailsSectionItem.vue'

interface SaleDetailsProps {
  section: SaleDetailsSection
  isMobile: boolean
}

const props = defineProps<SaleDetailsProps>()
const evenSection = computed(() =>
  props.section.items?.filter((_, index) => index % 2 === 0),
)
const oddsSection = computed(() =>
  props.section.items?.filter((_, index) => index % 2 === 1),
)
</script>
