<template>
  <RevContainer>
    <NoSales v-if="!hasSales"></NoSales>
    <div v-else>
      <SaleItem
        v-for="sale in sales"
        :key="sale.id"
        class="mb-52"
        :item="sale"
        @reload-sales="reloadSales"
      />
      <ClientOnly>
        <LazyLoad
          v-if="canFetchMore"
          :is-loading="isLoading"
          @load-more="fetchMore"
        />
      </ClientOnly>
    </div>
    <RevToast
      v-if="isInfoToastOpened"
      close-alternative-text="Close"
      :duration="10000"
      name="mysales-info-toast"
      :opened="isInfoToastOpened"
      :title="i18n(translations.toastTitle)"
      to="#toast-wrapper"
      variant="neutral"
      @close="isInfoToastOpened = false"
    >
      {{ i18n(translations.toastContent) }}
    </RevToast>
  </RevContainer>
</template>

<script setup lang="ts">
import { useRuntimeConfig } from '#imports'
import { computed, ref } from 'vue'

import { buybackCustomerAPI } from '@backmarket/http-api'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'
import { useHttpFetch } from '@backmarket/nuxt-module-http/useHttpFetch'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTheToast } from '@backmarket/nuxt-module-toast/useTheToast'
import { RevContainer } from '@ds/components/Container'
import { RevToast } from '@ds/components/Toast'

import { LazyLoad } from '#components'
import {
  type SaleItemType,
  mySalesAdapter,
} from '~/scopes/buyback/api/getCustomerSales/mySalesAdapter'

import translations from './MySales.translations'
import NoSales from './components/NoSales/NoSales.vue'
import SaleItem from './components/SaleItem/SaleItem.vue'

const runtimeConfig = useRuntimeConfig()
const { KILL_MY_SALES_MORE } = runtimeConfig.public
const isLoading = ref(false)
const sales = ref<SaleItemType[]>([])
const { openErrorToast } = useTheToast()

const next = ref<string | null>(null)
const { data } = await useHttpFetch(buybackCustomerAPI.getCustomerSales)
const isInfoToastOpened = ref(false)
const i18n = useI18n()

if (data.value) {
  const mySalesData = mySalesAdapter(data.value)
  /**
   * https://backmarket.atlassian.net/browse/CSS-174
   * Checking if at least on trade-in hasn't it shipping label yet
   * if so, show info toast
   */
  if (
    mySalesData?.items
      ?.map(({ timeline }) => timeline.steps[0].current)
      ?.some((item) => item)
  ) {
    isInfoToastOpened.value = true
  }
  sales.value = mySalesData?.items
  next.value = mySalesData?.next
}

const canFetchMore = computed(() => next.value && !KILL_MY_SALES_MORE)
const hasSales = computed(() => sales.value?.length > 0)

async function reloadSales(id: number) {
  const index = sales.value.findIndex((sale) => sale.id === id)
  const page = index === -1 ? null : Math.ceil((index + 1) / 10)
  if (page) {
    try {
      const response = await $httpFetch(buybackCustomerAPI.getCustomerSales, {
        queryParams: { page },
      })

      if (response) {
        const mySalesData = mySalesAdapter(response)
        const mySalesDataIndex = index - 10 * (page - 1)
        sales.value[index] = mySalesData.items[mySalesDataIndex]
      }
    } catch {
      openErrorToast()
    }
  }
}

async function fetchMore() {
  isLoading.value = true
  try {
    const response = await $httpFetch(buybackCustomerAPI.getCustomerSales, {
      queryParams: {
        page: next.value,
      },
    })

    if (response) {
      const mySalesData = mySalesAdapter(response)
      next.value = mySalesData?.next
      sales.value = [...sales.value, ...mySalesData.items]
    }
  } catch {
    // fail silently
  }

  isLoading.value = false
}
</script>
