<template>
  <RevAccordionItem
    class="px-32 py-20"
    :data-qa="`sales-saleitem-${itemId}-showinfo`"
    data-test="sales-show-more-details"
    @click="getSaleDetails"
  >
    <template #title>
      {{ i18n(translations.moreDetails) }}
    </template>

    <template #body>
      <RevSpinner
        v-if="status !== 'success' && status !== 'error'"
        alternative-text="loading"
        class="mx-auto my-12"
      />
      <template v-else>
        <SaleDetailsSection
          v-if="details.info"
          :is-mobile="isMobile"
          :section="details.info"
        />
        <SaleDetailsSection
          v-if="details.assessment"
          :is-mobile="isMobile"
          :section="details.assessment"
        />
        <SaleDetailsDocuments
          v-if="details.documents"
          :documents="details.documents"
        />
        <SaleDetailsSection
          v-if="details.shipping"
          :is-mobile="isMobile"
          :section="details.shipping"
        />
      </template>
    </template>
  </RevAccordionItem>
</template>

<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref } from 'vue'

import { buybackCustomerAPI } from '@backmarket/http-api'
import { useHttpFetch } from '@backmarket/nuxt-module-http/useHttpFetch'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import {
  Breakpoint,
  getCurrentBreakpoint,
} from '@backmarket/utils/dom/getCurrentBreakpoint'
import { RevAccordionItem } from '@ds/components/AccordionItem'
import { RevSpinner } from '@ds/components/Spinner'

import { getCustomerSaleDetailsAdapter } from '~/scopes/buyback/api/getCustomerSaleDetails/getCustomerSaleDetails.adapter'

import translations from './SaleDetails.translations'
import SaleDetailsDocuments from './SaleDetailsDocuments.vue'
import SaleDetailsSection from './SaleDetailsSection.vue'

interface SaleDetailsProps {
  itemId?: number
}

const i18n = useI18n()
const props = defineProps<SaleDetailsProps>()
const isExpand = ref<boolean>(false)
const isMobile = ref<boolean>(false)

const { data, status, execute } = useHttpFetch(
  buybackCustomerAPI.getCustomerSaleDetails,
  {
    pathParams: {
      id: props.itemId,
    },
    immediate: false,
    transform: getCustomerSaleDetailsAdapter,
  },
)

const details = computed(() => ({
  info: data.value?.details[0],
  assessment: data.value?.details[1],
  documents: data.value?.details[2],
  shipping: data.value?.details[3],
}))

async function getSaleDetails() {
  isExpand.value = !isExpand.value
  if (!data.value && isExpand.value) {
    await execute()
  }
}

function onResize(): void {
  isMobile.value = getCurrentBreakpoint() < Breakpoint.MD
}

function addEvents(): void {
  window.addEventListener('resize', onResize)
}

function removeEvents(): void {
  window.removeEventListener('resize', onResize)
}

onMounted(() => {
  addEvents()
  onResize()
})

onUnmounted(() => {
  removeEvents()
})
</script>
