export default {
  prepaidLabelTitle: {
    id: 'buyback_my_sales_ship_item_shipping_step_prepaid_label_title',
    defaultMessage: 'Print your prepaid shipping label',
  },
  prepaidLabelFirstParagraph: {
    id: 'buyback_my_sales_ship_item_shipping_step_prepaid_label_first_paragraph',
    defaultMessage:
      'You chose our prepaid shipping label to ship your item. You chose wisely.',
  },
  prepaidLabelSecondParagraph: {
    id: 'buyback_my_sales_ship_item_shipping_step_prepaid_label_second_paragraph',
    defaultMessage:
      'Print the shipping label below and stick it on the front of your package.',
  },
  infoBlockContent: {
    id: 'buyback_my_sales_ship_item_shipping_step_label_info_block',
    defaultMessage: `{count, plural, 
        =0 {Last day}
        other {# days left}
      } to send your package before your offer expires. You got this.`,
  },
  downloadShippingLabel: {
    id: 'buyback_my_sales_ship_item_shipping_step_prepaid_label_download',
    defaultMessage: 'Download shipping label',
  },
  emailPrepaidLabel: {
    id: 'buyback_my_sales_ship_item_shipping_step_prepaid_label_email',
    defaultMessage: 'Email shipping label',
  },
  kitTitle: {
    id: 'buyback_my_sales_ship_item_shipping_step_kit_title',
    defaultMessage: 'Prep and pack your item',
  },
  kitFirstParagraph: {
    id: 'buyback_my_sales_ship_item_shipping_step_kit_first_paragraph',
    defaultMessage:
      'You chose to ship your device with our mailing kit. Wise decision.',
  },
  kitSecondParagraph: {
    id: 'buyback_my_sales_ship_item_shipping_step_kit_second_paragraph',
    defaultMessage:
      'No printer needed, your mailing kit comes with everything you need.',
  },
  kitNotReceived: {
    id: 'buyback_my_sales_ship_item_shipping_step_kit_not_received',
    defaultMessage: 'Haven’t received your mailing kit?',
  },
  kitNotReceivedParagraph: {
    id: 'buyback_my_sales_ship_item_shipping_step_kit_not_received_paragraph',
    defaultMessage:
      'If you haven’t received your mailing kit yet, you can always ship your item for free with your own packaging.',
  },
  kitNotReceivedLink: {
    id: 'buyback_my_sales_ship_item_shipping_step_kit_not_received_link',
    defaultMessage: 'Download shipping label',
  },
  toastSuccessContent: {
    id: 'buyback_my_sales_ship_item_shipping_step_toast_success',
    defaultMessage: 'Email sent !',
  },
  toastErrorContent: {
    id: 'buyback_my_sales_ship_item_shipping_step_toast_error',
    defaultMessage: 'Something went wrong',
  },
  toastClose: {
    id: 'buyback_my_sales_ship_item_shipping_step_toast_close',
    defaultMessage: 'Close',
  },
}
