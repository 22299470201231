export default {
  close: {
    id: 'buyback_my_sales_bank_details_modal_close',
    defaultMessage: 'Close',
  },
  save: {
    id: 'buyback_my_sales_bank_details_modal_save',
    defaultMessage: 'Save',
  },
  introTitle: {
    id: 'buyback_my_sales_bank_details_modal_intro_title',
    defaultMessage: 'Let us pay you',
  },
  bankDetailsTitle: {
    id: 'buyback_my_sales_bank_details_modal_bank_details_title',
    defaultMessage: 'Banking information',
  },
  identityTitle: {
    id: 'buyback_my_sales_bank_details_modal_identity_title',
    defaultMessage: 'ID',
  },
  ssnTitle: {
    id: 'buyback_my_sales_bank_details_modal_ssn_title',
    defaultMessage: 'SSN',
  },
  birthdateTitle: {
    id: 'buyback_my_sales_bank_details_modal_birthdate_title',
    defaultMessage: 'Date of birth',
  },
  toastClose: {
    id: 'buyback_my_sales_ship_item_shipping_step_toast_close',
    defaultMessage: 'Close',
  },
  toastBankStepTitle: {
    id: 'buyback_my_sales_bank_details_bank_step_toast_title',
    defaultMessage: 'Moneybags!',
  },
  toastBankStepContent: {
    id: 'buyback_my_sales_bank_details_bank_step_toast_content',
    defaultMessage:
      'You can always update your payment details from your account.',
  },
  toastErrorTitle: {
    id: 'buyback_my_sales_bank_details_error_toast_title',
    defaultMessage: 'Oh no!',
  },
  toastErrorContent: {
    id: 'buyback_my_sales_bank_details_error_toast_content',
    defaultMessage: 'Something went wrong. Try again later.',
  },
  update: {
    defaultMessage: 'Update',
    id: 'myprofile_upload_id_fullfiled_button',
  },
  next: {
    id: 'buyback_my_sales_ship_item_next',
    defaultMessage: 'Next',
  },
}
