export default {
  title: {
    id: 'buyback_my_sales_bank_details_modal_intro_step_title',
    defaultMessage: 'Add your payment details',
  },
  subTitle: {
    id: 'buyback_my_sales_bank_details_modal_intro_step_sub_title',
    defaultMessage:
      'Let us pay you! Any information you share will only be used to verify your identity and send payment securely.',
  },
  idTitle: {
    id: 'buyback_my_sales_bank_details_modal_intro_step_id_title',
    defaultMessage: 'ID',
  },
  bankTitle: {
    id: 'buyback_my_sales_bank_details_modal_intro_step_bank_title',
    defaultMessage: 'Banking Information',
  },
  fiscalCodeTitle: {
    id: 'buyback_my_sales_bank_details_modal_intro_step_fiscal_code_title',
    defaultMessage: 'SSN',
  },
  birthdateTitle: {
    id: 'buyback_my_sales_bank_details_modal_intro_step_birthdate_title',
    defaultMessage: 'Personal information',
  },
  requiredLabel: {
    id: 'buyback_my_sales_bank_details_modal_intro_step_required_label',
    defaultMessage: 'Required for this step',
  },
  notRequiredLabel: {
    id: 'buyback_my_sales_bank_details_modal_intro_step_not_required_label',
    defaultMessage: 'Not required for this step',
  },
}
