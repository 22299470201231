<template>
  <div>
    <div v-if="isPrepaidLabel">
      <h3 class="heading-3 mt-32">
        {{ i18n(translations.prepaidLabelTitle) }}
      </h3>
      <p class="text-static-default-low body-1 mt-16">
        {{ i18n(translations.prepaidLabelFirstParagraph) }}
      </p>
      <p class="text-static-default-low body-1 mt-24">
        {{ i18n(translations.prepaidLabelSecondParagraph) }}
      </p>
      <div class="mt-16">
        <RevButton
          v-if="hasPrepaidLabel"
          class="mt-8"
          full-width="always"
          target="_blank"
          :to="prepaidLabel"
          variant="secondary"
        >
          {{ i18n(translations.downloadShippingLabel) }}
        </RevButton>

        <RevButton
          class="mt-8"
          full-width="always"
          variant="secondary"
          @click="sendShippingLabelByEmail"
        >
          {{ i18n(translations.emailPrepaidLabel) }}
        </RevButton>
      </div>
    </div>

    <div v-if="isKit">
      <h3 class="heading-3 mt-32">
        {{ i18n(translations.kitTitle) }}
      </h3>
      <p class="text-static-default-low body-1 mt-16">
        {{ i18n(translations.kitFirstParagraph) }}
      </p>
      <p class="text-static-default-low body-1 mt-24">
        {{ i18n(translations.kitSecondParagraph) }}
      </p>

      <RevAccordionList
        v-if="hasPrepaidLabel"
        class="mt-56"
        :has-dividers="false"
      >
        <RevAccordionItem>
          <template #title>
            {{ i18n(translations.kitNotReceived) }}
          </template>
          <template #body>
            <p class="text-static-default-low body-2">
              {{ i18n(translations.kitNotReceivedParagraph) }}
            </p>
            <RevLink
              class="text-static-default-low mt-24"
              target="_blank"
              :to="prepaidLabel"
            >
              {{ i18n(translations.kitNotReceivedLink) }}
            </RevLink>
          </template>
        </RevAccordionItem>
      </RevAccordionList>
    </div>

    <RevInfoBlock
      v-if="showInfoBlock"
      class="mt-56"
      :icon="IconSiren"
      :title="i18n(translations.infoBlockContent, { count: daysLeft })"
      variant="info"
    />
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { BUYBACK_SHIPPING_MODE } from '@backmarket/http-api/src/api-specs-buyback/constants'
import { postShippingLabelEmail } from '@backmarket/http-api/src/api-specs-buyback/customer/postShippingLabelEmail'
import { useHttpFetch } from '@backmarket/nuxt-module-http/useHttpFetch'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTheToast } from '@backmarket/nuxt-module-toast/useTheToast'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { RevAccordionItem } from '@ds/components/AccordionItem'
import { RevAccordionList } from '@ds/components/AccordionList'
import { RevButton } from '@ds/components/Button'
import { RevInfoBlock } from '@ds/components/InfoBlock'
import { RevLink } from '@ds/components/Link'
import { IconSiren } from '@ds/icons/IconSiren'

import translations from './ShippingStep.translations'

const props = defineProps<{
  shippingMode: string | undefined
  prepaidLabel: string | undefined
  daysLeft: number | undefined
  id: number
}>()
const i18n = useI18n()
const { openSuccessToast, openErrorToast } = useTheToast()

const isPrepaidLabel = computed(
  () => props.shippingMode === BUYBACK_SHIPPING_MODE.SELF_PACKAGED,
)
const hasPrepaidLabel = computed(() => !isEmpty(props.prepaidLabel))
const isKit = computed(() => props.shippingMode === BUYBACK_SHIPPING_MODE.KIT)
const showInfoBlock = computed(() => props.daysLeft !== undefined)

async function sendShippingLabelByEmail() {
  const { status } = await useHttpFetch(postShippingLabelEmail, {
    pathParams: {
      id: props.id,
    },
  })

  if (status.value === 'success') {
    openSuccessToast({
      title: i18n(translations.toastSuccessContent),
    })
  } else {
    openErrorToast({
      title: i18n(translations.toastErrorContent),
    })
  }
}
</script>
