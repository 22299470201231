<template>
  <div v-if="!pending">
    <div v-if="showForm">
      {{ i18n(translations.description) }}
      <FormGenerator
        class="mt-32"
        :form-config="formConfig"
        :form-id="formId"
        :has-submit-button="false"
        @submit="handleSubmit"
      />
    </div>
    <div v-else>
      {{ i18n(translations.previewDescription) }}
      <RevList class="mt-32" :hasExternalBorders="false">
        <RevListItemInteractive
          v-for="[label, description] in validApiValues"
          :key="label"
        >
          <template #label>
            {{ i18n(translations[label as keyof typeof translations]) }}
          </template>
          <template #description>
            {{ description }}
          </template>
        </RevListItemInteractive>
      </RevList>
    </div>
  </div>
  <div v-else>
    <RevLoadingScreen :text="i18n(translationsKyc.loadingDetails)" />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import {
  PAYMENT_INFOS_OPTIONS,
  type PaymentInfo,
  type PaymentInfosOptionsType,
} from '@backmarket/http-api/src/api-specs-buyback/customer'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevList } from '@ds/components/List'
import { RevListItemInteractive } from '@ds/components/ListItemInteractive'
import { RevLoadingScreen } from '@ds/components/LoadingScreen'

import FormGenerator, {
  type FormValues,
  type Input,
} from '~/scopes/buyback/components/FormGenerator/FormGenerator.vue'
import { generateAchForm } from '~/scopes/buyback/config/generators/generateAchForm'
import { generateBacsForm } from '~/scopes/buyback/config/generators/generateBacsForm'
import { generateIbanForm } from '~/scopes/buyback/config/generators/generateIbanForm'
import { useBankDetails } from '~/scopes/dashboard/my-sales/composables/useKycInformations'

import translationsKyc from '../KycDetailsModal.translations'

import translations from './BankDetails.translations'

function optionToBankType(option: PaymentInfosOptionsType | undefined) {
  switch (option) {
    case PAYMENT_INFOS_OPTIONS.BACS:
      return generateBacsForm().map((item) => ({
        ...item,
        cols: 6,
      }))
    case PAYMENT_INFOS_OPTIONS.ACH:
      return generateAchForm()
    case PAYMENT_INFOS_OPTIONS.SEPA:
    default:
      return generateIbanForm()
  }
}

const props = defineProps<{
  formId: string
  showForm: boolean
  bank: PaymentInfo | undefined
}>()
const emit = defineEmits<{
  submit: [values: FormValues]
}>()
const i18n = useI18n()
const formConfig: Array<Input> = [...optionToBankType(props.bank?.options?.[0])]

const { data, pending } = useBankDetails()

const validApiValues = computed(() => {
  const { iban, bic, routingNumber, accountNumber, sortCode } = data.value || {
    iban: null,
    bic: null,
    accountNumber: null,
    routingNumber: null,
    sortCode: null,
  }

  return Object.entries({
    iban,
    bic,
    routingNumber,
    accountNumber,
    sortCode,
  }).filter(([, val]) => Boolean(val))
})

function handleSubmit(values: FormValues) {
  emit('submit', values)
}
</script>
