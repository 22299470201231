import type { MonetaryAmount } from '@backmarket/http-api'
import type { GetCustomerSalesPayload } from '@backmarket/http-api/src/api-specs-buyback/customer/getCustomerSales'
import type {
  BuybackModelAction,
  BuybackModelInstruction,
  BuybackModelTimeline,
} from '@backmarket/http-api/src/api-specs-buyback/models'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { spacesToHyphens } from '@backmarket/utils/string/spacesToHyphens'
import { type Step } from '@ds/components/StatusTracker'

export const NO_DATA_STRING = '-'

export type FormattedStatusTimeline = Step & {
  current: boolean
  tag: string
  hint: string | null
  date: string | null
  details: string | null
}

interface InfoBlockData {
  content: string
  icon: string
  title: string
  variant: string
}

export interface SaleItemType {
  actions: BuybackModelAction[]
  id: number
  counterOfferPrice: MonetaryAmount | null
  price: MonetaryAmount
  title: string
  image: string
  tag: string
  timeline: {
    activeStep: string
    steps: FormattedStatusTimeline[]
  }
  infoBlockData: InfoBlockData | null
}

export interface MySalesFormatted {
  count: number
  next: string | null
  previous: string | null
  items: SaleItemType[]
}

const formatStatusTimeline = (
  statusTimeline: readonly BuybackModelTimeline[],
): FormattedStatusTimeline[] => {
  const i18n = useI18n()

  return statusTimeline.map((step) => ({
    current: step.current,
    tag: step.tag,
    hint: step.hint || '',
    label: step.label,
    key: spacesToHyphens(step.label.toLowerCase()),
    date: step.date
      ? i18n.date(new Date(step.date), {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
        })
      : '',
    details: step.info,
  }))
}

const getStatusTimelineActiveStep = (
  statusTimeline: FormattedStatusTimeline[],
) => {
  const { tag, key: activeStep } =
    statusTimeline.find((status) => status.current) || statusTimeline[0]

  return { tag, activeStep }
}

const getInfoData = (
  instruction: BuybackModelInstruction | undefined,
): InfoBlockData | null => {
  if (isEmpty(instruction)) {
    return null
  }
  const { body, icon, title: bannerTitle } = instruction

  return {
    content: body,
    icon,
    title: bannerTitle,
    variant: 'info',
  }
}

export const mySalesAdapter = (
  apiResponse: GetCustomerSalesPayload,
): MySalesFormatted => {
  const { count, next, previous, results } = apiResponse

  const nextPageNumber = next ? new URL(next).searchParams.get('page') : null
  const prevPageNumber = previous
    ? new URL(previous).searchParams.get('page')
    : null

  return {
    count,
    next: nextPageNumber,
    previous: prevPageNumber,
    items: results?.map((item) => {
      const { actions } = item
      const { price, id } = item.order
      const counterOfferPrice = item.order.counterOfferPrice || null
      const { title } = item.product
      const image = item.product.image.light

      const steps = formatStatusTimeline(item.order.statusTimeline)

      const { tag, activeStep } = getStatusTimelineActiveStep(steps)

      const timeline = {
        activeStep,
        steps,
      }

      return {
        actions,
        id,
        counterOfferPrice,
        price,
        title,
        image,
        tag,
        timeline,
        infoBlockData: getInfoData(item.instruction),
      }
    }),
  }
}
