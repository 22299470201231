import type { MonetaryAmount } from '@backmarket/http-api'

import { type HttpResponsePayload, createHttpEndpoint } from '../../utils'

export interface CounterOffer {
  comment: string
  initialOfferPrice: MonetaryAmount
  counterOfferPrice: MonetaryAmount
  remainingDays: number
  reasons: Array<string>
  attachments: Array<string>
}

export type CounterOfferResponse = HttpResponsePayload<CounterOffer>

/**
 * Buyback order counter offer details
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/buyback-bm-api/definition#tag/Customer/operation/getBuybackOrderCounterOffer}
 */
export const getCounterOffer = createHttpEndpoint<CounterOffer>({
  method: 'GET',
  operationId: 'getCounterOfferDetails',
  path: '/bm/buyback/v1/customer/orders/:id/counter-offer/pending',
})
