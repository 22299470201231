export default {
  description: {
    id: 'buyback_my_sales_bank_details_modal_ssn_view_description',
    defaultMessage:
      'Please enter the last 4 digits of your SSN (social security number) so we can confirm your identity. It’s secure and safe.',
  },
  previewDescription: {
    id: 'buyback_my_sales_bank_details_modal_ssn_view_preview_description',
    defaultMessage: 'You have already entered the following SSN last 4 digits:',
  },
  agentPreviewDescription: {
    id: 'buyback_admin_order_ssn_modal_description',
    defaultMessage:
      'This Back Market customer has already entered the following SSN last 4 digits:',
  },
  ssnPreviewLabel: {
    id: 'buyback_my_sales_bank_details_modal_ssn_view_input',
    defaultMessage: 'Last 4 digits of your SSN',
  },
  ssnTitle: {
    id: 'buyback_my_sales_bank_details_modal_ssn_title',
    defaultMessage: 'SSN',
  },
  drivingLicencePreviewLabel: {
    id: 'buyback_my_sales_kyc_id_tab_driving_licence',
    defaultMessage: 'Driving Licence',
  },
  infoBlockTitle: {
    id: 'buyback_my_sales_bank_details_modal_ssn_view_info_title',
    defaultMessage: 'Why do we need your SSN? ',
  },
  infoBlockDescription: {
    id: 'buyback_my_sales_bank_details_modal_ssn_view_info_description',
    defaultMessage:
      'In order to pay you, our payment provider requires the last 4 digits of your SSN to confirm your identity. Your SSN will be automatically deleted once your identity is confirmed and you have recieved your payment. You can find your SSN on your social security card, tax returns or bank statements.',
  },
  required: {
    id: 'buyback_shared_required_field',
    defaultMessage: 'This field is required',
  },
  lengthError: {
    id: 'buyback_my_sales_bank_details_modal_ssn_view_error_length',
    defaultMessage:
      'You must provide the last 4 digits of your social security number',
  },
}
