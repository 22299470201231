<template>
  <div v-if="!pending">
    <p class="text-static-default-low body-1 mb-20 mt-4">{{ description }}</p>

    <p class="text-static-default-low body-1 mt-22">
      {{ i18n(translations.warning) }}
    </p>

    <div v-if="showForm">
      <FormGenerator
        class="mt-28"
        :form-config="config"
        :form-id="formId"
        :has-submit-button="false"
        @submit="handleSubmit"
      ></FormGenerator>
    </div>

    <RevList v-else class="mt-22 px-24">
      <RevListItemStatic>
        <template #label>
          {{ i18n(translations.previewLabel) }}
        </template>
        <template #description>
          {{ formattedBirthdate }}
        </template>
      </RevListItemStatic>
    </RevList>
  </div>
  <div v-else>
    <RevLoadingScreen :text="i18n(translationsKyc.loadingDetails)" />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { isAgeLegal } from '@backmarket/utils/date/isAgeLegal'
import { FORM_VALID, required } from '@ds/components/Form'
import { RevList } from '@ds/components/List'
import { RevListItemStatic } from '@ds/components/ListItemStatic'
import { RevLoadingScreen } from '@ds/components/LoadingScreen'

import FormGenerator, {
  type FormValues,
  type Input,
} from '~/scopes/buyback/components/FormGenerator/FormGenerator.vue'
import AddressTranslations from '~/scopes/buyback/pages/Address.translations'
import { useBirthdate } from '~/scopes/dashboard/my-sales/composables/useKycInformations'

import { BUYBACK_MIN_AGE, BUYBACK_MIN_YEAR } from '../../../SaleItem/constants'
import translationsKyc from '../KycDetailsModal.translations'

import translations from './BirthdateDetails.translations'

const SHORT_DATE_FORMAT: Intl.DateTimeFormatOptions = {
  month: 'numeric',
  day: 'numeric',
  year: 'numeric',
}

const props = defineProps<{
  formId: string
  showForm: boolean
  id: number
}>()
const emit = defineEmits<{
  submit: [values: FormValues]
}>()
const i18n = useI18n()

const { data, pending } = useBirthdate(props.id)

const config: Array<Input> = [
  {
    id: 'birthdate',
    cols: 6,
    type: 'date',
    label: i18n(translations.inputLabel),
    validation: [
      function requireBirthdate(value, values: FormValues) {
        return required(i18n(AddressTranslations.formMandatoryError))(
          value.trim(),
          values,
          {},
        )
      },
      function validateBirthDate(value: string) {
        if (!value) {
          return FORM_VALID
        }

        const parsed = Date.parse(value)
        const birthdate = new Date(parsed)
        const isYoungEnough = birthdate.getFullYear() > BUYBACK_MIN_YEAR
        const isOldEnough = isAgeLegal({
          legalAge: BUYBACK_MIN_AGE,
          birthdate,
        })

        return isOldEnough && isYoungEnough
          ? FORM_VALID
          : i18n(translations.ageError)
      },
    ],
    value: '',
  },
]

const description = computed(() =>
  props.showForm
    ? i18n(translations.description)
    : i18n(translations.previewDescription),
)
const formattedBirthdate = computed(() =>
  data.value?.birthdate
    ? i18n.date(new Date(String(data.value.birthdate)), SHORT_DATE_FORMAT)
    : '',
)

function handleSubmit(values: FormValues) {
  emit('submit', values)
}
</script>
