<template>
  <RevDrawer
    :back-button-label="i18n(modalBuybackTranslations.backButtonLabel)"
    :close-button-label="i18n(modalBuybackTranslations.close)"
    :has-back-button="showBackButton"
    :name="name"
    :title="activeStepTitle"
    @back="goToPrevStep"
    @close="handleClose"
  >
    <template #body>
      <div class="pt-12">
        <Intro
          v-if="activeStep === 'intro'"
          :has-identity="hasIdentity"
          :has-ssn="hasSsn"
          :hasBankDetails="hasBankDetails"
          :hasBirthdate="hasBirthdate"
          @next="(id) => goToNextStep(id)"
        />
        <BirthdateDetails
          v-if="activeStep === 'birthdate'"
          :birthdate="kycDetails.birthdate"
          :form-id="FORM_IDS.BIRTHDATE"
          :hasBirthdate="hasBirthdate"
          :show-form="showBirthdateForm"
          @submit="handleBirthdateSubmit"
        />
        <BankDetails
          v-if="activeStep === 'bankDetails'"
          :banking-values="kycDetails.bankDetails"
          :form-id="FORM_IDS.BANK_DETAILS"
          :show-form="showBankForm"
          @submit="handleBankSubmit"
        />
        <Identity
          v-if="activeStep === 'identity'"
          :form-id="FORM_IDS.IDENTITY"
          :show-form="showIdentityForm"
          :step="identityStep"
          @submit="handleIdentitySubmit"
        />
        <SsnDetails
          v-if="activeStep === 'socialSecurityNumber'"
          :form-id="FORM_IDS.SSN"
          :preview-value="kycDetails.ssn.socialSecurityNumber"
          :show-form="showSsnForm"
          @submit="handleSsnSubmit"
        />
      </div>
    </template>
    <template #footer>
      <RevButton
        full-width="always"
        :loading="isLoading"
        variant="primary"
        @click="handleFooterBtn"
      >
        {{ footerBtnLabel }}
      </RevButton>
    </template>
  </RevDrawer>
</template>

<script setup lang="ts">
import { computed, reactive, ref } from 'vue'

import { buybackCustomerAPI, clientAPI } from '@backmarket/http-api'
import type {
  BankDetailsResponse,
  CustomerSsnResponse,
  IdentityDocumentsResponse,
} from '@backmarket/http-api/src/api-specs-buyback/customer'
import modalBuybackTranslations from '@backmarket/nuxt-layer-buyback/utils/Modal.translations'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'
import { useHttpFetch } from '@backmarket/nuxt-module-http/useHttpFetch'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTheToast } from '@backmarket/nuxt-module-toast/useTheToast'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { RevButton } from '@ds/components/Button'
import { RevDrawer } from '@ds/components/Drawer'
import { submitForm } from '@ds/components/Form'
import { closeModal } from '@ds/components/ModalBase'

import type { FormValues } from '~/scopes/buyback/components/FormGenerator/FormGenerator.vue'
import {
  type IdDocumentsType,
  KYC_IDS,
  type KycType,
  useBuybackConfig,
} from '~/scopes/buyback/config/useBuybackConfig'

import translations from './KycDetailsModal.translations'
import BankDetails from './bank/BankDetails.vue'
import BirthdateDetails from './birthdate/BirthdateDetails.vue'
import {
  type UnformattedIdentityPayload,
  identityPayloadAdapter,
} from './identity/Identity.utils'
import Identity from './identity/Identity.vue'
import Intro from './intro/Intro.vue'
import SsnDetails from './ssn/SsnDetails.vue'

interface KycDetails {
  bankDetails: BankDetailsResponse
  identityDocument: IdentityDocumentsResponse
  birthdate: string | null
  ssn: CustomerSsnResponse
}

type KycDetailsStep = KycType | 'intro'

const MODAL_TITLE = {
  intro: translations.introTitle,
  [KYC_IDS.BANK_DETAILS]: translations.bankDetailsTitle,
  [KYC_IDS.IDENTITY]: translations.identityTitle,
  [KYC_IDS.SSN]: translations.ssnTitle,
  [KYC_IDS.IDENTITY_INFORMATIONS]: translations.ssnTitle,
  [KYC_IDS.BIRTHDATE]: translations.birthdateTitle,
}

const FORM_IDS = {
  BANK_DETAILS: 'banking-form',
  IDENTITY: 'identity-form',
  SSN: 'ssn-form',
  BIRTHDATE: 'birthdate-form',
}

const props = defineProps<{
  name: string
  id: number
}>()

const identityStep = ref<'choice' | 'details'>('choice')
const activeStep = ref<KycDetailsStep>('intro')
const i18n = useI18n()
const emit = defineEmits(['is-loaded', 'close'])
const { kyc } = useBuybackConfig()
const { openErrorToast, openSuccessToast } = useTheToast()
const kycDetails = reactive<KycDetails>({
  bankDetails: {
    iban: null,
    bic: null,
    accountNumber: null,
    routingNumber: null,
    sortCode: null,
    bankDetailsDocument: null,
    isBankDetailsDocumentDeletable: false,
  },
  identityDocument: {
    documents: {},
    identityFulfilled: false,
    isIdentityDocumentDeletable: false,
  },
  birthdate: null,
  ssn: {
    socialSecurityNumber: null,
  },
})
const isLoading = ref(false)
const editMode = ref(false)

Promise.all([
  useHttpFetch(buybackCustomerAPI.getIdentityDocuments),
  useHttpFetch(buybackCustomerAPI.getBankDetails),
  useHttpFetch(buybackCustomerAPI.getCustomerBirthdate, {
    pathParams: {
      id: props.id,
    },
  }),
  ...(kyc[KYC_IDS.SSN]?.required
    ? [useHttpFetch(buybackCustomerAPI.getCustomerSsn)]
    : []),
]).then((responses) => {
  const hasErrors = responses.some((response) => response.error.value !== null)
  if (hasErrors) {
    openErrorToast({
      title: i18n(translations.toastErrorTitle),
      content: i18n(translations.toastErrorContent),
    })
  } else {
    if (responses[0].data.value) {
      kycDetails.identityDocument = responses[0].data.value
    }
    if (responses[1].data.value) {
      kycDetails.bankDetails = responses[1].data.value
    }
    kycDetails.birthdate = responses[2].data.value?.birthdate || null
    if (responses[3]?.data?.value) {
      kycDetails.ssn = responses[3].data.value
    }
  }
  emit('is-loaded')
})

const hasBirthdate = computed(() => !isEmpty(kycDetails.birthdate))
const hasIdentity = computed(() =>
  Boolean(kycDetails.identityDocument?.identityFulfilled),
)
const hasSsn = computed(() => !isEmpty(kycDetails.ssn.socialSecurityNumber))
const hasBankDetails = computed(() => {
  const { fields } = kyc[KYC_IDS.BANK_DETAILS]

  return fields.every(
    (field) =>
      !isEmpty(kycDetails.bankDetails?.[field as keyof BankDetailsResponse]),
  )
})
const showBackButton = computed(() => activeStep.value !== 'intro')
const showBirthdateForm = computed(
  () =>
    activeStep.value === 'birthdate' && (editMode.value || !hasBirthdate.value),
)
const showIdentityForm = computed(
  () =>
    activeStep.value === 'identity' && (editMode.value || !hasIdentity.value),
)
const showBankForm = computed(
  () =>
    activeStep.value === 'bankDetails' &&
    (editMode.value || !hasBankDetails.value),
)
const showSsnForm = computed(
  () =>
    activeStep.value === 'socialSecurityNumber' &&
    (editMode.value || !hasSsn.value),
)
const activeStepTitle = computed(() => {
  const title = MODAL_TITLE[activeStep.value]
  if (title) {
    return i18n(MODAL_TITLE[activeStep.value])
  }

  return ''
})

const closeBtn = computed(() => activeStep.value === 'intro')
const nextBtn = computed(
  () => showIdentityForm.value && identityStep.value === 'choice',
)
const saveBtn = computed(
  () =>
    showBirthdateForm.value ||
    showIdentityForm.value ||
    showBankForm.value ||
    showSsnForm.value,
)
const footerBtnLabel = computed(() => {
  if (closeBtn.value) {
    return i18n(translations.close)
  }
  if (nextBtn.value) {
    return i18n(translations.next)
  }

  if (saveBtn.value) {
    return i18n(translations.save)
  }

  return i18n(translations.update)
})

function handleClose() {
  emit('close')
}

function goToIntroStep() {
  identityStep.value = 'choice'
  editMode.value = false
  activeStep.value = 'intro'
}

function goToPrevStep() {
  if (activeStep.value === 'identity' && identityStep.value === 'details') {
    identityStep.value = 'choice'
  } else {
    goToIntroStep()
  }
}

function goToNextStep(id: KycDetailsStep) {
  activeStep.value = id
}

function handleFooterBtn() {
  if (closeBtn.value) {
    closeModal()
  } else if (nextBtn.value) {
    identityStep.value = 'details'
  } else if (saveBtn.value) {
    switch (activeStep.value) {
      case 'birthdate':
        submitForm(FORM_IDS.BIRTHDATE)
        break
      case 'bankDetails':
        submitForm(FORM_IDS.BANK_DETAILS)
        break
      case 'identity':
        submitForm(FORM_IDS.IDENTITY)
        break
      case 'socialSecurityNumber':
        submitForm(FORM_IDS.SSN)
        break

      default:
    }
  } else {
    editMode.value = true
  }
}

async function handleBirthdateSubmit(values: FormValues) {
  isLoading.value = true
  try {
    await $httpFetch(buybackCustomerAPI.setCustomerBirthdate, {
      pathParams: {
        id: props.id,
      },
      body: {
        birthdate: values.birthdate,
      },
    })
    openSuccessToast({
      title: i18n(translations.toastBankStepTitle),
      content: i18n(translations.toastBankStepContent),
    })
    kycDetails.birthdate = String(values.birthdate)
    goToIntroStep()
    editMode.value = false
  } catch (error) {
    openErrorToast({
      title: i18n(translations.toastErrorTitle),
      content: i18n(translations.toastErrorContent),
    })
  } finally {
    isLoading.value = false
  }
}

async function handleBankSubmit(values: FormValues) {
  try {
    isLoading.value = true
    const body = new FormData()

    for (const [key, value] of Object.entries(values)) {
      if (value) {
        body.append(key, value.toString())
      }
    }

    const data = (await $httpFetch(clientAPI.putBankDetails, {
      body,
    })) as BankDetailsResponse

    const { fields } = kyc[KYC_IDS.BANK_DETAILS]

    if (kycDetails.bankDetails === null) {
      kycDetails.bankDetails = {
        iban: null,
        bic: null,
        accountNumber: null,
        routingNumber: null,
        sortCode: null,
        bankDetailsDocument: null,
        isBankDetailsDocumentDeletable: false,
      }
    }
    fields.forEach((field) => {
      kycDetails.bankDetails[field] = data[field]
    })
    openSuccessToast({
      title: i18n(translations.toastBankStepTitle),
      content: i18n(translations.toastBankStepContent),
    })
    goToIntroStep()
  } catch (errors) {
    openErrorToast({
      title: i18n(translations.toastErrorTitle),
      content: i18n(translations.toastErrorContent),
    })
  } finally {
    isLoading.value = false
  }
}

async function handleIdentitySubmit(
  values: UnformattedIdentityPayload,
  type: IdDocumentsType,
) {
  isLoading.value = true
  const apiPayload = identityPayloadAdapter(values, type)
  const body = new FormData()

  for (const [key, value] of Object.entries(apiPayload)) {
    if (value) {
      body.append(key, value as File)
    }
  }
  try {
    const response = await $httpFetch(
      buybackCustomerAPI.postIdentityDocuments,
      {
        body,
      },
    )

    kycDetails.identityDocument.identityFulfilled = true
    kycDetails.identityDocument.isIdentityDocumentDeletable =
      response.isIdentityDocumentDeletable

    openSuccessToast({
      title: i18n(translations.toastBankStepTitle),
      content: i18n(translations.toastBankStepContent),
    })

    identityStep.value = 'choice'
    goToIntroStep()
  } catch (error) {
    openErrorToast({
      title: i18n(translations.toastErrorTitle),
      content: i18n(translations.toastErrorContent),
    })
  } finally {
    isLoading.value = false
  }
}

async function handleSsnSubmit(values: FormValues) {
  isLoading.value = true
  try {
    await $httpFetch(buybackCustomerAPI.putCustomerSsn, {
      body: {
        socialSecurityNumber: values.ssn,
      },
    })
    openSuccessToast({
      title: i18n(translations.toastBankStepTitle),
      content: i18n(translations.toastBankStepContent),
    })

    kycDetails.ssn.socialSecurityNumber = String(values.ssn)
    goToIntroStep()
    editMode.value = false
  } catch (error) {
    openErrorToast({
      title: i18n(translations.toastErrorTitle),
      content: i18n(translations.toastErrorContent),
    })
  } finally {
    isLoading.value = false
  }
}
</script>
