<template>
  <div>
    <p class="text-static-default-low mt-4">
      {{ description }}
    </p>
    <div v-if="showForm" class="mt-32">
      <FormGenerator
        :extra-css="['!gap-28']"
        :form-config="formConfig"
        :form-id="formId"
        :has-submit-button="false"
        @submit="handleSubmit"
      />
      <RevInfoBlock
        class="mt-32"
        :content="i18n(translations.infoBlockDescription)"
        :icon="IconLock"
        :title="i18n(translations.infoBlockTitle)"
        variant="default"
      />
    </div>

    <RevList v-else class="mt-22 px-24" :hasExternalBorders="false">
      <RevListItemStatic>
        <template #label>
          {{ i18n(translations.ssnPreviewLabel) }}
        </template>
        <template #description>
          {{ previewValue }}
        </template>
      </RevListItemStatic>
    </RevList>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { maxLength, minLength, required } from '@ds/components/Form'
import { RevInfoBlock } from '@ds/components/InfoBlock'
import { RevList } from '@ds/components/List'
import { RevListItemStatic } from '@ds/components/ListItemStatic'
import { IconLock } from '@ds/icons/IconLock'

import FormGenerator, {
  type FormValues,
  type Input,
} from '~/scopes/buyback/components/FormGenerator/FormGenerator.vue'

import translations from './SsnDetails.translations'

const SSN_LENGTH = 4

const props = defineProps<{
  showForm: boolean
  formId: string
  previewValue: string | null
}>()

const i18n = useI18n()
const emit = defineEmits<{
  submit: [values: FormValues]
}>()

const formConfig: Array<Input> = [
  {
    id: 'ssn',
    cols: 6,
    type: 'text',
    label: i18n(translations.ssnPreviewLabel),
    value: '',
    validation: [
      (accountNumber: string, values: FormValues) =>
        required(i18n(translations.required))(accountNumber, values, {}),

      (accountNumber: string, values: FormValues) =>
        maxLength(
          SSN_LENGTH,
          i18n(translations.lengthError, {
            length: 4,
          }),
        )(accountNumber, values, {}),

      (accountNumber: string, values: FormValues) =>
        minLength(
          SSN_LENGTH,
          i18n(translations.lengthError, {
            length: 4,
          }),
        )(accountNumber, values, {}),
    ],
  },
]

const description = computed(() =>
  props.previewValue
    ? i18n(translations.previewDescription)
    : i18n(translations.description),
)

function handleSubmit(values: FormValues) {
  emit('submit', values)
}
</script>
