import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { maxLength, minLength, required } from '@ds/components/Form'

import type {
  FormValues,
  InputText,
} from '~/scopes/buyback/components/FormGenerator/FormGenerator.vue'

import translations from './generateAchForm.translations'

const ACCOUNT_NUMBER_MIN_LENGTH = 4
const ACCOUNT_NUMBER_MAX_LENGTH = 17
const ROUTING_NUMBER_LENGTH = 9

export const generateAchForm = (): Array<InputText> => {
  const i18n = useI18n()

  return [
    {
      id: 'routingNumber',
      type: 'text',
      cols: 6,
      label: i18n(translations.routingNumber),
      description: i18n(translations.routingNumberDescription),
      value: '',
      validation: [
        function requireRoutingNumber(routingNumber: string, form: FormValues) {
          return required(i18n(translations.required))(routingNumber, form, {})
        },
        function routingNumberMaxLength(
          routingNumber: string,
          form: FormValues,
        ) {
          return maxLength(
            ROUTING_NUMBER_LENGTH,
            i18n(translations.routingNumberError, {
              length: ROUTING_NUMBER_LENGTH,
            }),
          )(routingNumber, form, {})
        },
        function routingNumberMinLength(
          routingNumber: string,
          form: FormValues,
        ) {
          return minLength(
            ROUTING_NUMBER_LENGTH,
            i18n(translations.routingNumberError, {
              length: ROUTING_NUMBER_LENGTH,
            }),
          )(routingNumber, form, {})
        },
      ],
      extraCss: ['mb-8'],
    },
    {
      id: 'accountNumber',
      cols: 6,
      type: 'text',
      label: i18n(translations.accountNumber),
      description: i18n(translations.accountNumberDescription),
      value: '',
      validation: [
        function requiredAccountNumber(
          accountNumber: string,
          form: FormValues,
        ) {
          return required(i18n(translations.required))(accountNumber, form, {})
        },
        function accountNumberMaxLength(
          accountNumber: string,
          form: FormValues,
        ) {
          return maxLength(
            ACCOUNT_NUMBER_MAX_LENGTH,
            i18n(translations.accountNumberError, {
              min: ACCOUNT_NUMBER_MIN_LENGTH,
              max: ACCOUNT_NUMBER_MAX_LENGTH,
            }),
          )(accountNumber, form, {})
        },
        function accountNumberMinLength(
          accountNumber: string,
          form: FormValues,
        ) {
          return minLength(
            ACCOUNT_NUMBER_MIN_LENGTH,
            i18n(translations.accountNumberError, {
              min: ACCOUNT_NUMBER_MIN_LENGTH,
              max: ACCOUNT_NUMBER_MAX_LENGTH,
            }),
          )(accountNumber, form, {})
        },
      ],
    },
  ]
}
