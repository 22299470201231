import { useAsyncData } from '#imports'

import type {
  BankDetailsResponse,
  BirthdateResponse,
  CustomerIdentityResponse,
  PaymentInfoResponse,
} from '@backmarket/http-api/src/api-specs-buyback/customer'
import { getAsyncDataKey } from '@backmarket/nuxt-module-http-v2/getAsyncDataKey'
import { useHttpFetch } from '@backmarket/nuxt-module-http-v2/useHttpFetch'

import { ENDPOINTS_PATH } from '../../constants'

export function useBirthdate(id: number) {
  const $httpFetch = useHttpFetch()
  const key = getAsyncDataKey('kyc-payment-info-birthday')

  return useAsyncData(key, () => {
    return $httpFetch<BirthdateResponse>(ENDPOINTS_PATH.BIRTHDATE, {
      pathParams: { id },
    })
  })
}

export function useBankDetails() {
  const $httpFetch = useHttpFetch()
  const key = getAsyncDataKey('kyc-payment-info-bank-details')

  return useAsyncData(key, () => {
    return $httpFetch<BankDetailsResponse>(ENDPOINTS_PATH.BANK_DETAILS)
  })
}

export function useIdentityInformations() {
  const $httpFetch = useHttpFetch()
  const key = getAsyncDataKey('kyc-payment-info-bank-details')

  return useAsyncData(key, () => {
    return $httpFetch<CustomerIdentityResponse>(
      ENDPOINTS_PATH.CUSTOMER_IDENTITY,
      {
        method: 'GET',
      },
    )
  })
}

export async function useKycInformations(id: number) {
  const $httpFetch = useHttpFetch()
  const key = getAsyncDataKey('kyc-payment-info', {
    id,
  })

  return await useAsyncData(key, () => {
    return $httpFetch<PaymentInfoResponse>(ENDPOINTS_PATH.PAYMENT_INFO, {
      pathParams: { id },
    })
  })
}
