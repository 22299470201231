import type { CounterOffer } from '@backmarket/http-api/src/api-specs-buyback/customer/getCounterOffer'
import { getFilenameFromUrl } from '@backmarket/nuxt-layer-buyback/utils/helpers'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'

const isPdf = (fileUrl: string) => {
  const { extension } = getFilenameFromUrl(fileUrl)

  return extension === 'pdf'
}

export const counterOfferAdapter = ({
  reasons = [],
  attachments = [],
  counterOfferPrice,
  initialOfferPrice,
  comment = '',
  remainingDays = 0,
}: CounterOffer) => {
  const i18n = useI18n()

  const reasonsList = reasons.map((reason) => ({
    id: reason,
    label: reason,
  }))

  const imgAttachments = attachments.filter((attachment) => !isPdf(attachment))
  const pdfAttachments = attachments.filter(isPdf)

  const documentsList = pdfAttachments.map((documentUrl) => {
    const { name } = getFilenameFromUrl(documentUrl)

    return {
      id: documentUrl,
      src: documentUrl,
      displayName: name,
    }
  })

  const imagesList = imgAttachments.map((imgUrl) => ({
    id: imgUrl,
    src: imgUrl,
  }))

  const formattedCounterOfferPrice = i18n.price(counterOfferPrice)
  const formattedPrice = i18n.price(initialOfferPrice)

  const remainingDaysString = remainingDays ? remainingDays.toString() : '0'

  return {
    reasonsList,
    documentsList,
    imagesList,
    formattedCounterOfferPrice,
    formattedPrice,
    comment,
    remainingDays: remainingDaysString,
  }
}
